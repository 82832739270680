$(document).ready(function() {
  // this ensures that this js only runs if you're on the goal settings page
  if ($(".goal-settings").length === 0) {
    return;
  }

  // this is possibly an antipattern but it grabs the slug of the goal in
  // question from the goal panel. This way we only have to put it in one
  // place in the html and it's available to all the functions below that are
  // bound to element events
  var slug = $(".goal-settings").data("slug").toString();

  // remember what settings tab you were on
  $(".goal-settings-tabs a").click(function(e) {
    e.preventDefault();
    localStorage.setItem("com.beeminder.goalSettingsTab", $(e.currentTarget).attr("href"));
  });

  if (localStorage.getItem("com.beeminder.goalSettingsTab")) {
    $(".goal-settings-tabs a[href='" + localStorage.getItem("com.beeminder.goalSettingsTab") + "']").click();
  }

  $(".goal-settings-form .datepicker.xmin").b_datepicker({ endDate: "0d" });
  $(".goal-settings-form .datepicker.xmax").b_datepicker({ startDate: "0d" });

  $(".ratchet-form").submit(function(e) {
    $(this).find("input[type='submit']").prop("disabled", true)
    var error = false;
    var days = $(this).find("input[type='text']").val();
    var rtype = $(this).data("ratchtype");
    var lowerlimit = $(this).data("lowerlimit");
    var upperlimit = $(this).data("upperlimit");
    if ($.trim(days).length === 0) {
      error = "A buffer value is required";
    } else if (isNaN(days)) {
      error = "Number of "+rtype+" must be a number";
    } else if (rtype == "units" && days >= upperlimit) {
      error = "Number of "+rtype+" cannot be larger than the current buffer ("+upperlimit+")";
    } else if (rtype == "days" && days > upperlimit) {
      error = "Number of "+rtype+" must be less than the current safety buffer";
    } else if (days < 0) {
      error = "Number of "+rtype+" can't be less than zero";
    } else if (days < lowerlimit) {
      error = "Number of "+rtype+" cannot be less than " + lowerlimit;
    }
    if (error) {
      e.preventDefault();
      beetils.showFlash("error", error)
      $(this).find("input[type='submit']").removeProp("disabled");
      return false;
    }
    return true;
  });

  // WEEN/RASH weekends-off form submit  
  $(".goal-settings .weekends-form .weekends-room button").click(function() {
    var rate = $(".weekends-form input[name='weekend_rate']").val()
    rate = parseFloat(rate)
    if (isNaN(rate)) {
      beetils.showFlash("error", "You must submit a number for the weekend allowance")
      return false;
    }
    var params = $.extend(beetils.tokenParams(), {
      "_method": "PUT",
      "weekend_rate": rate,
      "weekends_off": rate !== 0 
    });
    var message = "Updated your weekend settings";
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + slug + ".json",
      data: params,
      success: function(data) {
        beetils.showFlash("success", message);
        beetils.showInfinibee(slug);
        beetils.pollUntilBeebrainUpdates("me", slug, function(data2) {
          //success function
          beetils.hideFlash();
          beetils.hideInfinibee(slug);
          beetils.showFlash("success", "Graph updated!");
          goaltils.updateWithGoalData(data2);
        }, function(data) {
          //error function
          beetils.hideFlash();
          beetils.hideInfinibee(slug);
          beetils.showFlash("error", "There was a problem updating your graph");
        });
      },
      error: function(data) {
        beetils.showFlash("error", "Something went wrong changing your weekends off, please refresh the page and try again.");
      }
    });
  });

  // bind weekends off checkbox on goal page
  // PHAT/MOAR weekends-off form submit (checkbox)
  $(".goal-settings .weekends-form input[type='checkbox']").change(function(e) {
    var params = $.extend(beetils.tokenParams(), {
      "_method": "PUT",
      "weekend_rate": 0,
      "weekends_off": $(this).prop("checked") ? true : false
    });
    var message = "You'll now have weekends off for this goal. Refreshing your graph...";
    if (!$(this).prop("checked")) {
      message = "You'll no longer have weekends off for this goal. Refreshing your graph...";
    }
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + slug + ".json",
      data: params,
      success: function(data1) {
        beetils.showFlash("success", message);
        beetils.showInfinibee(slug);
        beetils.pollUntilBeebrainUpdates("me", slug, function(data2) {
          //success function
          beetils.hideFlash();
          beetils.hideInfinibee(slug);
          beetils.showFlash("success", "Graph updated!");
          goaltils.updateWithGoalData(data2);
        }, function(data) {
          //error function
          beetils.hideFlash();
          beetils.hideInfinibee(slug);
          beetils.showFlash("error", "There was a problem updating your graph");
        });
      },
      error: function(data) {
        beetils.showFlash("error", "Something went wrong changing weekends off, please refresh the page and try again.");
      }
    });
  });

  // take a break scheduler
  $(".datepicker[name=break_start]").b_datepicker({startDate:"+7d"})
  $(".datepicker[name=break_end]").b_datepicker({startDate:"+7d"})

  $("#schedule-break-error").hide();
  $("form.schedule-break").submit(function(e) {
    var start  = new moment($("input[name='break_start']").val())
    var end    = new moment($("input[name='break_end']").val())
    var today  = new moment().startOf('day')
    var akrHor = new moment().add(7, 'days').startOf('day')

    var error =
      start > end ? "Your dates are out of order — try again!" :
      start < today ? "Can't schedule a break starting in the past — try again!" :
      start < akrHor ? "Can't schedule a break before the akrasia horizon — try again!" :
      end.valueOf()/1000 > $(this).data("tfin")? "Your break ends after your goal date — try again!" :
      start.valueOf()/1000 > $(this).data("tfin")? "Your break starts after your goal date — try again!" :
        false
    if (error) {
      $("#schedule-break-error").html(error).show()
      $(this).find("input:submit").prop("disabled",true)
    }
    return !error
  })

  $("form.schedule-break .datepicker").change(function() {
    $("form.schedule-break input:submit").prop("disabled",false);
    $("#schedule-break-error").hide();
  })

  // THIS TOGGLES SHOWING THE FULL ROAD DIAL
  $(".toggle-road-dial").click(function(e) {
    e.preventDefault();
    $(".rate-form .goalval").removeClass("hidden");
    $(".rate-form .goaldate").removeClass("hidden");
    $(".rate-form span.octicon").show().removeClass("hidden");
    $(".rate-form .form-row").prop("title", "Ex out a field (so it's grayed out) and we will infer its value from the other two")
    $(this).hide();
  });

  function greyDate() {
    $(".rate-form .form-row").removeClass("disabled")
      .find("span.octicon")
      .removeClass("octicon-pencil")
      .addClass("octicon-x");
    $(".rate-form .form-row.goaldate").addClass("disabled")
      .find("span.octicon")
      .removeClass("octicon-x")
      .addClass("octicon-pencil");
  }

  function greyTotal() {
    $(".rate-form .form-row").removeClass("disabled")
      .find("span.octicon")
      .removeClass("octicon-pencil")
      .addClass("octicon-x");
    $(".rate-form .form-row.goalval").addClass("disabled")
      .find("span.octicon")
      .removeClass("octicon-x")
      .addClass("octicon-pencil");
  }

  function greyRate() {
    $(".rate-form .form-row").removeClass("disabled")
      .find("span.octicon")
      .removeClass("octicon-pencil")
      .addClass("octicon-x");
    $(".rate-form .form-row.goalrate").addClass("disabled")
      .find("span.octicon")
      .removeClass("octicon-x")
      .addClass("octicon-pencil");
  }

  $(".rate-form input[name='goaldate']").b_datepicker({startDate:"+7d"});

  $(".rate-form .form-row span.octicon").click(function(e) {
    var row = $(this).parent();
    if (row.hasClass("disabled")) {
      row.find("input").focus();
    } else {
      if (row.hasClass("goalval")){
        greyTotal();
      } else if (row.hasClass("goaldate")) {
        greyDate();
      } else if (row.hasClass("goalrate")) {
        greyRate();
      }
    }
  });

  $(".rate-form").find("select, input[name='goalrate'], .plus, .minus").on('focus', function(e) {
    if ($(".rate-form .form-row.goalrate").hasClass("disabled")) {
      greyDate();
    }
  });

  $(".rate-form").find("input[name='goalval']").on('focus', function(e) {
    if ($(".rate-form .form-row.goalval").hasClass("disabled")) {
      greyRate();
    }
  });

  $(".rate-form").find("input[name='goaldate']").on('focus', function(e) {
    if ($(".rate-form .form-row.goaldate").hasClass("disabled")) {
      greyTotal();
    }
  });

  $(".rate-form input, .rate-form select").on('change keyup', function() {
    inferOddOut();
  })

  $(".rate-form .plus, .rate-form .minus").click(function() {
    inferOddOut();
  });

  function inferOddOut() {
    if ($(".rate-form .form-row.goalrate").hasClass("disabled")) {
      inferRate();
    } else if ($(".rate-form .form-row.goalval").hasClass("disabled")) {
      inferTotal();
    } else if ($(".rate-form .form-row.goaldate").hasClass("disabled")) {
      inferDate();
    }
  }


/* CALCULATE THE MISSING TFIN / VFIN / RFIN
 * these are all assuming that the road does nothing after the akrasia horizon,
 * so could be completely screwed up, as they do not walk the road matrix
 * */
  function calcTFin(t0, v0, _, v, r) {
    if (t0===null || v0===null || v===null || r===null ) return null;
    if (r === 0) //! ==
      return 4102444799000; // 2099-12-31T00:00:00Z
    return t0 + (v-v0)/r;
  }
  function calcVFin(t0, v0, t, _, r) {
    if (t0===null || v0===null || t===null || r===null) return null; //! ==
    return (+v0 +r*(+t-t0));
  }
  function calcRFin(t0, v0, t, v, _) {
    if (t0===null || v0===null || t===null || v===null) return null
    if (Math.floor(t0/SIRU.d) === Math.floor(t/SIRU.d)) return 0
    return (v-v0)/(t-t0)
  }
var SIRU = {
  'd': 60*60*24,
  'w': 60*60*24*7,
  'm': 60*60*24*365.25/12,
  'y': 60*60*24*365.25
}
  //TODO: what's up with timezones & these dates?
  function infer(type) {
    var t0   = moment().add(1, "week")
    t0       = (new moment(t0.format("YYYY-MM-DD"))).unix();
    var v0   = $(".rate-form form").data("rah");
    var runits = $(".rate-form select[name='runits']").val();

    if ($(".rate-form .goaldate").hasClass("disabled")) {
      var tfin = null;
    } else {
      var tfin = new moment($(".rate-form input[name='goaldate']").val());
      tfin = tfin.unix()
    }
    if ($(".rate-form .goalrate").hasClass("disabled")) {
      var rfin = null;
    } else {
      var rfin = parseFloat($(".rate-form input[name='goalrate']").val());
      rfin /= SIRU[runits];
    }
    if ($(".rate-form .goalval").hasClass("disabled")) {
      var vfin = null;
    } else {
      var vfin = parseFloat($(".rate-form input[name='goalval']").val());
    }

    var args = [t0, v0, tfin, vfin, rfin]
    var result = null;
    if        (type === "date") {
      result = calcTFin.apply(null, args);
    } else if (type === "rate") {
      result = calcRFin.apply(null, args);
      result *= SIRU[runits]; // got to scale back to correct runits
    } else if (type === "value") {
      result = calcVFin.apply(null, args);
    }
    return result;
  }

  function inferDate() {
    var ts = infer("date");
    var goaldate = new moment(0);
    goaldate.add(ts, "seconds");
    $(".rate-form input[name='goaldate']").val(goaldate.format("YYYY-MM-DD"));
  }

  function inferRate() {
    var rt = infer("rate");
    if (Math.round(rt) === rt) {
      rt = Math.round(rt)
    } else if (Math.round(rt*10) === rt * 10) {
      rt = rt.toFixed(1)
    } else if (Math.round(rt) !== rt) {
      rt = rt.toFixed(2)
    }
    $(".rate-form input[name='goalrate']").val(rt);
  }

  function inferTotal() {
    var total = infer("value");
    if (Math.round(total) === total) {
      total = Math.round(total)
    } else if (Math.round(total*10) === total * 10) {
      total = total.toFixed(1)
    } else if (Math.round(total) !== total) {
      total = total.toFixed(2)
    }
    $(".rate-form input[name='goalval']").val(total);
  }

  inferOddOut();

  $('li.legend div:not(.hide)').click(function(){$(this).parent().find('.hide').slideToggle()})

  $(".max-safety-form input[type='checkbox']").change(function() {
    toggleMaxSafetyForm();
  });

  function toggleMaxSafetyForm() {
    if ($(".max-safety-form input[type='checkbox']").prop("checked")) {
      $(".max-safety-form form .form-row.stepper").fadeIn();
    } else {
      $(".max-safety-form form .form-row.stepper").fadeOut();
    }
  }
  toggleMaxSafetyForm();

  $("form.max-safety").submit(function() {
    if (!$(".max-safety-form input[name='cap_safebuf']").prop("checked")) {
      $(this).find("input[name='goal[max_safety]']").val("");
    }
    return true;
  });

  $(".delete-form input[type='radio']").change(function(e) {
    $(".delete-form .del-prompt").text($(".delete-form input[name='reason']:checked").data("del-prompt"))
  });

  $(".delete-form input[type='radio']").change();

  // Begin PPRs
  $("#settings-tab .ppr input[type='checkbox']").change(function() {
    var params = $.extend(beetils.tokenParams(), {
      "_method": "PUT",
      "pessimistic": $(this).prop("checked") ? true : false
    });

    var message = "Turned on pessimistic presumptive datapoints";
    if (!$(this).prop("checked")) {
      message = "Turned off pessimistic presumptive datapoints";
    }
    $.ajax({
      type: "POST",
      url: "/api/v1/users/me/goals/" + slug + ".json",
      data: params,
      success: function(data) {
        beetils.showFlash("success", message);
      },
      error: function(data) {
        beetils.showFlash("error", "Something went wrong changing this setting, please refresh the page and try again.");
      }
    });
  });
  // End PPRs

  // bind change slug confirmation
  $("form#change-slug-form").submit(function(e) {
    e.preventDefault();
    $("input[name='confirm_slug_change']").removeClass("error");
    var text = $("input[name='confirm_slug_change']").val();
    if (text === slug) {
      $("#change-slug-modal").on("hidden.bs.modal", function() {
        $(".goal-form .slug-field").fadeIn();
        $(".goal-form .slug-field").focus();
        $(".goal-form .current-slug").hide();
      })
      $("#change-slug-modal").modal("hide");
    } else {
      $("input[name='confirm_slug_change']").addClass("error");
    }
    return false;
  });

  $("#change-slug-modal").on("shown.bs.modal", function() {
    $("input[name='confirm_slug_change']").focus();
  })
  // end change slug confirmation
  // bind no-excuses mode opt-in
  $("#noexcuses-modal input[name='confirm_noexcuses']").keyup(function(e) {
    if (/paying is not punishment/i.test($(this).val())) {
      $("#noexcuses-modal #optingin").prop("disabled", false)
      $(this).removeClass("error")
    }
  })
  if ($("#noexcuses-modal").length > 0) {
    // if no excuses is turned on, we don't include the modal, and 
    // the js here gets sad calling .form() on a nil jquery object
    // (nil because noexcuses-modal is not found)
    // so this is a hacky way to only set up the on-submit function
    // when the dang thing is present. ideally we'd put this
    // control js somewhere else, and only include it when the 
    // the modal in question is included.
    $("#noexcuses-modal #optingin").form().on('submit', function(e){
      e.preventDefault();
      $("#noexcuses-modal #optingin").prop("disabled", true)
      if (/paying is not punishment/i.test($("#noexcuses-modal input[name='confirm_noexcuses']").val())) {
        beetils.showFlash("success", "opting in...");
        $("#noexcuses-modal").modal("hide");
        this.submit();
      } else {
        // TODO: can't use flash error here beause it's hidden behind the modal
        $("#noexcuses-modal #optingin").prop("disabled", true)
        $("#noexcuses-modal input[name='confirm_noexcuses']").addClass("error")
      }
    })
  }

  $("#noexcuses-modal").on("shown.bs.modal", function() {
    $("input[name='confirm_noexcuses']").focus();
  })
  // end no-excuses 

  $(".fine-print-row a.edit-fine-print").click(function(e) {
    e.preventDefault();
    $(this).hide();
    $(".fine-print-row .fine-print-text").hide();
    $(".fine-print-row textarea").show();
    return false;
  })

  // error checking on basic goal settings form
  $(".basic-settings-form").submit(function(e) {
    if ($("#flash-js").css("height") === "75px") { beetils.hideFlash(); }
    var error = null;
    var goal_slug = $(this).find("input#goal_slug");
    var current_slug = slug;
    if (goal_slug.val() !== current_slug) {
      error = beetils.slugError(goal_slug.val())
      if (error) { 
        goal_slug.addClass("error flag")
          .one("click focus", function(){$(this).removeClass("error flag")});
      }
    } 
    if (!error && $("#goal_yaxis").val().length > 70) {
      error = "Y-Axis label is too long -- It must be less than 70 characters";
      $(this).find("input#goal_yaxis").addClass("error")
        .one("click focus", function(){$(this).removeClass("error")});
    }
    if (error) {
      e.preventDefault();
      $(this).find("input[type='submit']").removeAttr("disabled");
      beetils.showFlash("error", error);
      return false;
    }
    return true;
  });
  // end error checking on basic goal settings form

  // bind "zeno now" button
  $("#zenonow").click(function(e) {
    e.preventDefault();
    $(".reminders-form form").append("<input type='hidden' name='zenonow' value='true'>").submit();
  });

  $(".goal-yaw input[name='goal[yaw]']").change(function(e) {
    let unitsText = "days"
    if ($(this).attr("id") === "-1-goal[yaw]") {
      unitsText = $(".post-derail-respite").data("gunits")
    }
    $(".pdr-units").text(unitsText)
  })

  // functions for managing relative vs absolute tmin settings
  function bind_tmin_select() {
    let tmin_val = $(".goal-settings-form #goal_tmin_rel").val()
    toggle_tmin_type(tmin_val)
    $(".goal-settings-form #goal_tmin_rel").change((evt)=>{
      let _this = $(evt.currentTarget)
      let days = _this.val()
      toggle_tmin_type(days)
    });
  }
  function toggle_tmin_type(val) {
    $(".goal-settings-form input#goal_tmin").toggle(val === "tmin")
    if (val !== "tmin") {
      $(".goal-settings-form input#goal_tmin").val('')
    }
  }
  bind_tmin_select();

});
