//only used in goal settings

$(document).ready(function() {
  // resend supporter conf emails
  var bindResendMailerButtons = function() {
    $(".resend-conf").click(function() {
      var params = beetils.tokenParams();
      params = $.extend(params, {
        "resend_confirmation": $(this).data("supporter-email")
      });
      var _this = $(this);
      $.ajax({
        type: "POST",
        url: "/api/v1/users/me/goals/" + $(this).data("slug") + "/supporters.json",
        data: params,
        success: function(data) {
          _this.removeClass("octicon-mail");
          _this.addClass("octicon-check");
        },
        error: function(data) {
          alert("There was a problem resending the confirmation email");
        }
      });
    });
  };
  bindResendMailerButtons();

  // delete supporter
  var bindDeleteSupporterButtons = function() {
    $(".delete-supporter").click(function() {
      var params = beetils.tokenParams();
      params = $.extend(params, {
        "delete_supporter": $(this).data("supporter-email")
      });
      var _this = $(this);
      $.ajax({
        type: "POST",
        url: "/api/v1/users/me/goals/" + $(this).data("slug") + "/supporters.json",
        data: params,
        success: function(data) {
          $(".form-row[data-supporter-email='" + _this.data("supporter-email") + "']").remove();
        },
        error: function(data) {
          alert("There was a problem deleting that supporter");
        }
      });
    });
  };
  bindDeleteSupporterButtons();
  // add supporter
  var bindSupportersForm = function() {
    $("#supporters-form").submit(function(e) {
      e.preventDefault();
      var slug = $(this).data("slug");
      var email = $(this).find(".new-supporter").val()
      var params = beetils.tokenParams();
      params = $.extend(params, {
        "supporter": email
      });
      var __form = $(this)

      $.ajax({
        type: "POST",
        url: "/api/v1/users/me/goals/" + slug + "/supporters.json",
        data: params,
        success: function(data) {
          $(".supportings").append('<div class="supporting form-row" data-supporter-email="' + email + '">' + email + '<div class="buttons"><div class="octicon octicon-x delete-supporter" data-slug="' + slug + '" data-supporter-email="' + email + '"></div></div></div>')
          bindDeleteSupporterButtons()
          $(".new-supporter").val("")
          __form.find("input[type='submit']").prop("disabled", false)
        },
        error: function(data) {
          beetils.showFlash("error",
            "There was a problem adding that supporter"
          )
          __form.find("input[type='submit']").prop("disabled", false)
        }
      });
    });
  }
  bindSupportersForm();
  // delete invite 
  var bindDeleteInviteButtons = function() {
    $(".delete-invite").click(function() {
      var params = beetils.tokenParams();
      params = $.extend(params, {
        "delete_invite": $(this).data("invite-code")
      });
      var _this = $(this);
      $.ajax({
        type: "POST",
        url: "/api/v1/users/me/goals/" + _this.data("slug") + "/invites.json",
        data: params,
        success: function(data) {
          let findrow = `section.waiting .supporting[data-invitee="${_this.data("invitee")}"]`
          $(findrow).remove();
        },
        error: function(data) {
          alert("There was a problem deleting that supporter");
        }
      });
    });
  };
  bindDeleteInviteButtons();

  var bindGroupiesForm = function() {
    $("#groupies-form").submit(function(e) {
      e.preventDefault();
      var slug = $(this).data("slug");
      var username = $(this).find(".new-groupie").val()
      var params = beetils.tokenParams();
      params = $.extend(params, {
        "invitee": username 
      });
      var __form = $(this)

      $.ajax({
        type: "POST",
        url: "/api/v1/users/me/goals/" + slug + "/invites.json",
        data: params,
        success: function(data) {
          let newrow = buildInviteeRow(username, slug, data.invite_code)
          $("#none-so-far").remove();
          $("section.waiting .supportings").append(newrow)
          bindDeleteInviteButtons()
          $(".new-groupie").val("")
          __form.find(".alert").remove()
          __form.find("input[type='submit']").prop("disabled", false)
          beetils.hideFlash()
        },
        error: function(data) {
          beetils.showFlash("error",
            "There was a problem adding that supporter"
          )
          let response = JSON.parse(data.responseText)
          __form.find(".alert").remove()
          __form.prepend('<div class="alert alert-danger">'+response.error+'</div>')
          __form.find("input[type='submit']").prop("disabled", false)
        }
      });
    });

  }
  bindGroupiesForm();
  var buildInviteeRow = function(invitee, goalname, confcode) {
    let row = $("<div></div>").addClass("form-row supporting").text(invitee)
    row.attr("data-invitee", invitee)
    let buttons = $("<div></div>").addClass("buttons")
    let del = $("<div></div>").addClass("octicon octicon-x delete-invite")
    del.attr("data-invitee", invitee)
       .attr("data-slug", goalname)
       .attr("data-invite-code", confcode)

    buttons.append(del)
    row.append(buttons)
    return row
  }
  // copy all supporters to other goals
  var bindCopyAllSupportersForm = function() {
    $("#copy-supporters-form").submit(function(e) {
      e.preventDefault();
      var slug = $(this).data("slug");
      var params = beetils.tokenParams();
      params = $.extend(params, {
        "copy_all": true
      });
      var _this = $(this);

      $.ajax({
        type: "POST",
        url: "/api/v1/users/me/goals/" + slug + "/supporters.json",
        data: params,
        success: function(data) {
          _this.find(".success").animate({ opacity: 1 }, 200).delay(1000).animate({ opacity: 0 }, 500);
        },
        error: function(data) {
          alert("There was a problem copying your supporters");
        }
      });
    });
  }
  bindCopyAllSupportersForm();
});
